import React, { useEffect, useState, useRef, useCallback } from "react";
import "./WritePitch.css";
import Webcam from "react-webcam";
import pic from "../mepic.png";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import { newButton } from "./background";
import { useDispatch, useSelector } from "react-redux";
import { getPitch, getAuth } from "../Action";
import { SERVER } from "../server/server";
import { toast } from "react-toastify";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import { useReactMediaRecorder } from "react-media-recorder";
import Fab from "@mui/material/Fab";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import VideocamIcon from "@mui/icons-material/Videocam";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BackGround } from "./background";
import { getImgURL } from "../util/getImgUrl";
import MeLogo from "../assets/MeLogoMain";
import { Store } from "@mui/icons-material";
import MainModal from "../components/MainModal/MainModal";
import BackBtn from "../components/componentsB/btn/backBtn/BackBtn";
import { useWindowSize } from "../Hooks/useWindowSize";
import { onUplProg } from "../assets/common/onUplProg";
import UplProgBar from "../components/componentsC/uplProgBar/UplProgBar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeletePitch = () => {
  const dispatch = useDispatch();
  // for delete pitch
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const deletePitchVideo = () => {
    dispatch(getPitch("delete", null, () => {
      console.log("deleting...")
    }));
    setOpenDel(false);
    setAnchorEl(null);
  };
  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "#2f2841",
    border: "none",
    borderRadius: "5px",
    boxShadow: 24,
    padding: "1.5rem 1.5rem 0rem 1.5rem",
  };
  return (
    <>
      <Button
        onClick={() => setOpenDel(true)}
        style={{
          color: "#E74646",
          cursor: "pointer",
          "&:hover": { color: "#EFEFEF" },
        }}
      >
        <DeleteIcon />
        Delete Pitch
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDel}
        onClose={handleCloseDel}
        closeAfterTransition
      >
        <Box sx={styleDelete}>
          <h6
            id="transition-modal-description"
            style={{ color: "#fff", textAlign: "center" }}
          >
            Do you want to delete Pitch Video ?
          </h6>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              padding: "1rem",
            }}
          >
            <Button
              onClick={handleCloseDel}
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
              }}
            >
              Cancel
            </Button>

            <Button
              varient="contained"
              onClick={() => deletePitchVideo()}
              variant="contained"
              size="large"
              sx={{
                ...newButton,
                py: "6px",
                width: "6rem",
                fontSize: "75%",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};





function WritePitch() {
  const STORE = useSelector((state) => state.getPitch);
  const navigate = useNavigate();
  const resize = useWindowSize()
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [pitchVdo, setPitchVdo] = useState("");
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [handlePitchValue, setHandlePitchValue] = useState("");
  const [showButton, setShowButton] = useState(false);
  const bg = { background: "black", minHeight: "100vh", padding: "1rem", overflow: 'hidden' };
  const saveBtn = { ...newButton };
  const webcamRefs = useRef(null);
  const [opneWebcam, setOpenWebcam] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [retake, setRetake] = useState(false);
  const [seeVideo, setSeeVideo] = useState(false);
  const [seeYourPitch, setSeeYourPitch] = useState(false);
  const [pleaseWait, setPleaseWait] = useState(false);
  const [nowSubmit, setNowSubmit] = useState(false);
  const [hide, setHide] = useState(true);
  const [seeSubmit, setSeeSubmit] = useState(false);
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const [openDel, setOpenDel] = useState(false);
  const [pitchType, setPitchType] = useState(false);
  const [progress, setProgress] = useState(false)
  // for timer
  const timerRef = useRef(null);
  const [seeRecordingTime, setSeeRecordingTime] = useState(false);
  const [recordingDuratoin, setRecordingDuratoin] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [getVideoUrl, setGetVideoUrl] = useState("");
  const mediaRecorderRefs = useRef(null);
  const [recordedChunkss, setRecordedChunkss] = useState([]);
  const [statuss, setStatuss] = useState("");
  // for Main Modal
  const [changeView, setChangeView] = useState(1)
  const [openMainMOdal, setOpenMainModal] = useState(false);
  const [closeMainMOdal, setCloseMainModal] = useState(false);

  const videoSize = (size) => {
    if (size[0] <= 390) {
      return '320'
    }
    if (size[0] <= 500 && size[0] >= 390) {
      return '350'
    }
    else if (size[0] <= 700 && size[0] >= 500) {
      return '450'
    }
    else if (size[0] <= 900 && size[0] >= 700) {
      return '700'
    }
    else if (size[0] >= 900) {
      return '900'
    }
  }

  // timmer function started here
  const startTimer = () => {
    let duration = 0;
    timerRef.current = setInterval(() => {
      duration++;
      setRecordingDuratoin(duration);
    }, 1000);
  };
  const stopTimer = () => clearInterval(timerRef.current)
  // timer function end here

  const handleClickOpen = () => {
    setOpen(true)
    setPitchType(true)
    setPitchVdo('')
  }

  // pitch video by Aatif started here
  // let datas = STORE.userPitch?.message?.pitchVdo;


  function openWebcamCamera() {
    setOpenMainModal(true)
    setChangeView(1)
    setOpenWebcam(true);
    setAnchorEl(null);
  }

  const handleDataAvailables = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunkss((prev) => prev.concat(data));
      }
    },
    [setRecordedChunkss]
  );

  // after clicking start button
  const handleStartCaptureVideo = useCallback(() => {
    setCaptureVideo(true);
    // console.log("started");
    mediaRecorderRefs.current = new MediaRecorder(webcamRefs.current.stream, {
      mimeType: "video/webm",
      audioBitsPerSecond: 128000,
    });

    mediaRecorderRefs.current.addEventListener(
      "dataavailable",
      handleDataAvailables
    );

    mediaRecorderRefs.current.addEventListener("start", () => {
      setStatuss("Recording");
    });

    mediaRecorderRefs.current.addEventListener("stop", () => {
      setStatuss("Stopped");
    });

    mediaRecorderRefs.current.start();
    startTimer();
    setSeeRecordingTime(true);
  }, [webcamRefs, setCaptureVideo, mediaRecorderRefs, handleDataAvailables]);

  // after clicking STOP VIDEO CAM
  const handleStopCaptureVideo = useCallback(() => {
    setChangeView(2)
    mediaRecorderRefs.current.stop();
    setCaptureVideo(true);
    setOpenWebcam(false);
    stopTimer();
    setSeeYourPitch(false);
    setRetake(true);
    setSeeVideo(true);
    setHide(false);
  }, [mediaRecorderRefs, setCaptureVideo, setVideoUrl, recordedChunkss]);

  // for retake video
  function handleRetakeVideo() {
    stopTimer()
    setOpenWebcam(true);
    setSeeVideo(false);
    setCaptureVideo(false);
    setRetake(false);
    setRecordingDuratoin(0);
    setRecordedChunkss([]);
    setChangeView(1)
  }

  const handleDownloads = useCallback(() => {
    setSeeYourPitch(false);
    if (recordedChunkss.length) {
      const blobs = new Blob(recordedChunkss, {
        type: "video/webm",
      });
      const Url_video = URL.createObjectURL(blobs);
      setVideoUrl(Url_video);
    }
    setOpenWebcam(false);
    setRetake(true);
    setSeeVideo(true);
    setChangeView(2)
  }, [recordedChunkss]);

  const handleMainModalClose = () => {
    setHide(true)
    setSeeSubmit(false)
    stopTimer();
    setOpenWebcam(false);
    setCaptureVideo(false);
    // mediaRecorderRefs.current.stop();
    // handleStopCaptureVideo()
    setRecordingDuratoin(0);
    setRecordedChunkss([]);
    setChangeView(0)
    setOpenMainModal(false)
  }



  // pitch video by Aatif end here

  const styleSheet = {
    fontSize: "150%",
    fontWeight: "bold",
    fontFamily: "Times New Roman",
    margin: "15px 0px",
    color: "white",
  };
  const AccordianBackground = {
    ...BackGround,
    color: "white",
    margin: "10px 0 10px 0",
    border: "1px solid white",
  };

  const handleChange = (e) => {
    setHandlePitchValue(e.target.value);
  };
  // vdo section

  // console.log("pichVdo", pitchVdo);

  const vdoUpload = async (e, type) => {
    setProgress(true)
    const formData = new FormData();
    if (type == "file") {
      // console.log("file", e.target.files);
      formData.append("profiles", e.target.files[0], e.target.files[0].name);
      // console.log("formData", e.target.files[0], e.target.files[0].name);
    } else {
      formData.append("profiles", e[0], "rec" + Date.now());
    }

    setLoading(true);
    // console.log();
    // await axios({
    //   method: "post",
    //   url: `${SERVER}/pdfFile`,
    //   data: formData,
    // })
    await
      axios.post(
        `${SERVER}/pdfFile`, formData, { onUploadProgress: onUplProg }
      )
        .then((response) => {
          setProgress(false)

          setPitchVdo(response.data.file);
          setPitchType(true)
          setGetVideoUrl(response.data.file);
          setPleaseWait(false);
          setNowSubmit(true);
          // handleMainModalClose();
          setSeeSubmit(true);
          toast.success(response.data.success);
          setLoading(false);
          setAnchorEl(null);
        })
        .catch((err) => {
          toast.error("upload failed go back and try again", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
  };

  function myConfirmFnc() {
    setRecordingDuratoin(0);
    setPleaseWait(true);
    setRetake(false);
    vdoUpload(recordedChunkss, "rec");
  }


  const submitPitch = async () => {
    setChangeView(0)
    setNowSubmit(false);
    setCaptureVideo(false);
    setHide(true);
    setSeeSubmit(false)
    setRecordedChunkss([]);
    setPitchType(false)
    if (showButton) {
      dispatch(
        getPitch("update", false, () => {
          dispatch(getPitch('get', false, () => {
            console.log("getting...")
          }))
        }, handlePitchValue.split(" "), pitchVdo)
      );
    } else {
      dispatch(getPitch("add", false, () => {
        dispatch(getPitch('get', false, () => {
          console.log("getting...")
        }))
      }, handlePitchValue.split(" "), pitchVdo));
    }

    setRetake(false);
    setSeeVideo(false);
  };


  function VideoMOdalComponent({ props }) {
    const { newButton, handleMainModalClose, pleaseWait, nowSubmit, videoUrl, retake, handleRetakeVideo, myConfirmFnc, seeSubmit, submitPitch } = props
    return (
      <div className="h-100 d-flex justify-content-center align-items-center flex-column" style={{ position: 'relative' }}>
        {
          !seeSubmit && <div style={{ ...newButton, position: 'absolute', right: 30, top: 20, cursor: 'pointer' }} onClick={handleMainModalClose}>
            <CloseIcon />
          </div>
        }

        <div className="text-center">
          {pleaseWait ? (
            <h3 style={{ color: "white", fontFamily: "Times New Roman" }}>
              Please Wait{" "}
              <section class="pitch_dots-container">
                <div class="pitch_dot"></div>
                <div class="pitch_dot"></div>
                <div class="pitch_dot"></div>
              </section>
            </h3>
          ) : (
            ""
          )}
          {nowSubmit ? (
            <h3
              style={{
                color: "rgb(187, 11, 11)",
                fontFamily: "Times New Roman",
                fontWeight: "700",
                fontSize: "1.5rem"
              }}
            >
              NOW SUBMIT PITCH{" "}
              {/* <section class="dots-container">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                      </section> */}
            </h3>
          ) : (
            ""
          )}
        </div>
        <div>
          <video
            // style={{ height: "17rem", width: "20rem" }}
            style={{
              // boxShadow: "black 5px 5px 10px 5px",
              borderRadius: "10px",
              border: "2px solid white",
            }}
            controls
            type="video/mp4"
            controlsList="nodownload"
            muted
            width={videoSize(resize)}
            // height={500}
            src={videoUrl}
          ></video>
        </div>
        <div className="d-flex justify-content-between" style={{ margin: "1rem 1rem" }}>
          {retake ? (
            <button
              // className="mx-2"
              style={{
                ...newButton,
                fontSize: ".7rem",
                padding: "6px 33px",
                borderRadius: "4px",
                margin: "0px 6px",
              }}
              onClick={handleRetakeVideo}
            >
              RETAKE
            </button>
          ) : (
            ""
          )}
          {retake ? (
            <button
              style={{
                ...newButton,
                fontSize: ".7rem",
                padding: "6px 29px",
                borderRadius: "4px",
                margin: "0px 6px",
              }}
              onClick={myConfirmFnc}
            >
              CONFIRM
            </button>
          ) : (
            ""
          )}{" "}
          {seeSubmit ? (
            <Button
              onClick={submitPitch}
              variant="contained"
              size="large"
              sx={{
                ...saveBtn,
                width: "10rem",
                margin: "5px",
                fontSize: "60%",
              }}
            >
              Submit pitch
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  const RecVid = React.useMemo(() => ({ props }) => {
    const { opneWebcam, handleMainModalClose, captureVideo, newButton, handleStopCaptureVideo, handleStartCaptureVideo, statuss, recordingDuratoin, seeYourPitch, handleDownloads } = props
    const widthIn = 2;
    const width = `${widthIn * parseFloat(getComputedStyle(document.documentElement).fontSize)
      }px`;

    return (
      <div className="row p-3 d-flex justify-content-center align-items-center" style={{ overflow: 'auto' }}>

        <div className="col-12">
          {opneWebcam ? (
            <>
              <div className="text-center" style={{ position: 'relative' }}>
                <div style={{ ...newButton, position: 'absolute', right: 14, top: 0, zIndex: 9999, cursor: 'pointer' }} onClick={handleMainModalClose}> <CloseIcon /> </div>
                <h3 style={{ color: "white", fontSize: "1.3rem" }}>
                  {
                    recordingDuratoin > 0 && <>{recordingDuratoin} Sec &nbsp;</>
                  }
                  {statuss && statuss === "Recording" ? (
                    <>
                      <span>
                        {statuss}{" "}
                        <section className="pitch_dots-container">
                          <div className="pitch_dot" style={{ color: 'red' }}></div>
                          <div className="pitch_dot" style={{ color: 'red' }}></div>
                          <div className="pitch_dot" style={{ color: 'red' }}></div>
                        </section>
                      </span>
                      {/* <div>{recordingDuratoin} Sec</div> */}
                    </>
                  ) : statuss === "Stopped" ? (
                    ""
                  ) : (
                    ""
                  )}{" "}
                </h3>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="col-lg-12 mt-3 col-md-12 d-flex justify-content-center align-items-center">
          {opneWebcam ? (
            <Webcam
              width="70%"
              // height="50%"
              style={{ border: "2px solid white", borderRadius: "12px" }}
              audio={true}
              muted={true}
              mirrored={true}
              ref={webcamRefs}
              videoConstraints={{ aspectRatio: { max: 2, min: 1.75 }, echoCancellation: true, noiseSuppression: true }}
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-12 my-2 d-flex justify-content-center align-items-center">
          {opneWebcam &&
            (captureVideo ? (
              <button
                className="mx-2"
                style={{
                  ...newButton,
                  fontSize: ".7rem",
                  padding: "6px 17px",
                  borderRadius: "4px",
                }}
                onClick={handleStopCaptureVideo}
              >
                STOP CAPTURE
              </button>
            ) : (
              <button
                className="mx-2"
                style={{
                  ...newButton,
                  fontSize: ".7rem",
                  padding: "6px 17px",
                  borderRadius: "4px",
                }}
                onClick={handleStartCaptureVideo}
              >
                START CAPTURE
              </button>
            ))}

          {seeYourPitch ? (
            <button
              style={{
                ...newButton,
                fontSize: ".7rem",
                padding: "6px 17px",
                borderRadius: "4px",
              }}
              onClick={() => handleDownloads()}
            >
              See Your Pitch
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }, [changeView])



  var WebCamModal = {
    1: <Modal
      open={openMainMOdal}
      onClose={handleMainModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backdropFilter: "blur(10px)", backgroundColor: "rgba(255, 255, 255, 0.5)" }}
    >
      <RecVid props={{ webcamRefs, opneWebcam, captureVideo, newButton, handleStopCaptureVideo, handleMainModalClose, handleStartCaptureVideo, statuss, recordingDuratoin, seeYourPitch, handleDownloads }} />
    </Modal>,
    2: <Modal
      open={openMainMOdal}
      onClose={handleMainModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ backdropFilter: "blur(7px)", backgroundColor: "rgba(255, 255, 255, 0.5)" }}

    >
      <VideoMOdalComponent props={{ newButton, handleMainModalClose, pleaseWait, nowSubmit, videoUrl, retake, handleRetakeVideo, myConfirmFnc, seeSubmit, submitPitch }} />
    </Modal>
  }

  //  handle POP
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePop = () => setAnchorEl(null);

  // deleting pitch video
  const handleCloseDel = () => setOpenDel(false);
  const deletePitchVideo = () => {
    dispatch(getPitch("delete", null, () => console.log("deleting...")));
    setOpenDel(false);
    setAnchorEl(null);
    setPitchVdo("");
  };
  const widthIn = 20;
  // Calculate the width value in pixels
  const width = `${widthIn * parseFloat(getComputedStyle(document.documentElement).fontSize)
    }px`;
  const styleDelete = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // padding: "1.5rem 1.5rem 0rem 1.5rem",
    padding: "1rem 1rem",
    color: "black",
    borderRadius: "5px",
  };

  // for pitch
  useEffect(() => {
    console.log("get started");
    dispatch(getPitch("get", null, () => console.log("getting...")));
    if (
      STORE.userPitch?.message?.pitch &&
      STORE.userPitch?.message?.pitch.length > 0 &&
      // STORE.userPitch?.message?.pitchVdo &&
      !STORE.loading
    ) {
      setHandlePitchValue(STORE.userPitch?.message?.pitch.join(" "));
      // setPitchVdo(STORE.userPitch?.message?.pitchVdo);
      setShowButton(true);
    }
  }, [
    STORE.userPitch?.message?.pitch.length,
    // STORE.userPitch?.message?.pitchVdo,
  ]);

  // for getting chunck file
  useEffect(() => {
    if (recordedChunkss.length) {
      const blobs = new Blob(recordedChunkss, {
        type: "video/webm",
      });

      console.log(recordedChunkss);
      // vdoUpload(recordedChunkss,"rec")
      console.log(blobs);
      const Url_video = URL.createObjectURL(blobs);
      setVideoUrl(Url_video);
      // setRecordedChunkss([]);
    }
  }, [recordedChunkss]);

  return (
    <div className="container-fluid text-center" style={bg}>
      {
        progress ?
          <UplProgBar variant="determinate" />
          : ''
      }
      <div className="row">
        <div className="ml-auto mt-5">
          <BackBtn />
        </div>
        <div className="col-md-12 MainDivWritePitch">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // marginTop: ".5rem",
              marginBottom: "1rem",
              // paddingTop: "1rem",
            }}
          >
            <MeLogo />
          </div>
          <div className="row mainDiv">
            <div className="col-md-12">
              <Fab
                sx={{
                  ml: "auto",
                  textTransform: "none",
                  ...saveBtn,
                  fontSize: "60%",
                }}
                onClick={() => navigate("/MainDashboard/MyPitch/PitchInfo")}
                variant="extended"
              >
                How Will I Create A Pitch ?
                {/* <QuestionMarkIcon style={{ fontSize: "150%" }} /> */}
              </Fab>
              <h2 className="" style={styleSheet}>
                {pitchType ? '' : 'The Most Important 500 Words You Will Ever Write or Speak!'}
              </h2>

              {/* video tag and text area */}
              <div className="row">
                {pitchType ?
                  <div className=" col-md-12 mx-auto mt-3 pitchTextVideo">
                    {pitchVdo === '' ?
                      <textarea style={{ padding: "5px", borderRadius: '10px', width: '50%' }} onChange={handleChange}
                        value={handlePitchValue} name="description"
                        placeholder="Create your Pitch" id="" cols="90" rows="13"></textarea>
                      :
                      <video
                        src={pitchVdo}
                        type="video/mp4"
                        controlsList="nodownload"
                        autoPlay
                        controls
                        muted
                        height="300"
                        style={{
                          width: "50%",
                          boxShadow: "black 5px 5px 10px 5px",
                          borderRadius: "10px",
                          border: "2px solid white",
                        }}
                      />
                    }
                    <div className="d-flex justify-content-around pitchButton">
                      <Button
                        onClick={() => {

                          setPitchType(false)
                          setPitchVdo('')
                        }
                        }
                        variant="contained"
                        size="large"
                        sx={{
                          ...newButton,
                          width: "8rem",
                          margin: "5px",
                          fontSize: "60%",
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        onClick={submitPitch}
                        variant="contained"
                        size="large"
                        sx={{
                          ...newButton,
                          width: "8rem",
                          margin: "5px",
                          fontSize: "60%",
                        }}
                      >
                        Submit pitch
                      </Button>
                    </div>
                  </div> :
                  <div
                    className={
                      STORE.userPitch?.message?.pitchVdo &&
                        STORE.userPitch?.message?.pitch
                        ? "col-md-12  vdo_txt d-flex justify-content-md-around  justify-content-center"
                        : "col-md-6 mx-auto"
                    }
                    style={{ padding: "1rem" }}
                  >
                    <div>
                      <input
                        type="file"
                        accept="video/*"
                        id="vdo"
                        hidden
                        onChange={(e) => {
                          vdoUpload(e, "file");
                        }}
                      />
                      {STORE.userPitch?.message?.pitchVdo &&
                        STORE.userPitch?.message?.pitchVdo.length > 0 && (
                          <div
                            style={
                              {
                                //  marginRight: "2rem",
                                marginTop: "1rem"
                              }}>
                            <video
                              src={getImgURL(STORE.userPitch?.message?.pitchVdo)}
                              type="video/mp4"
                              controlsList="nodownload"
                              autoPlay
                              controls
                              muted
                              width="320"
                              height="180"
                              style={{
                                boxShadow: "black 5px 5px 10px 5px",
                                borderRadius: "10px",
                                border: "2px solid white",
                              }}
                            />
                          </div>
                        )}
                    </div>
                    {open ||
                      (STORE.userPitch?.message?.pitch &&
                        STORE.userPitch?.message?.pitch.length >= 1) ? (
                      <>
                        <div>
                          <p className="text-white p-4">{handlePitchValue}</p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                }
              </div>

            </div>
          </div>
          {pitchType ? '' :
            <div
              className="row"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                margin: ""
              }}
            >
              {/* pitch video button */}
              {hide ? (
                <div>
                  <Button
                    size="large"
                    sx={{
                      ...saveBtn,
                      width: "10rem",
                      margin: "5px",
                      fontSize: "60%",
                    }}
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                  >
                    Pitch Video
                  </Button>
                  {/* pop over */}
                  <Popover
                    id={id}
                    open={pop}
                    anchorEl={anchorEl}
                    onClose={handlePop}
                    anchorOrigin={{
                      vertical: "bottom",
                    }}
                    rootClose
                  >
                    <Typography
                      onClick={() => openWebcamCamera()}
                      sx={{ cursor: "pointer", width: "12rem", px: 1, pt: 1 }}
                    >
                      {" "}
                      <VideocamIcon /> Record Pitch
                    </Typography>
                    <Typography
                      onChange={(e) => {
                        vdoUpload(e, "file");
                      }}
                      component="label"
                      sx={{ cursor: "pointer", width: "12rem", p: 1 }}
                      loading={progress}
                      loadingIndicator="Uploading…"
                    >
                      {" "}
                      <input hidden type="file" accept="video/*" />{" "}
                      <FileUploadIcon /> Upload Pitch
                    </Typography>
                    {STORE.userPitch?.message?.pitchVdo.length != 0 ? (
                      <Typography>{<DeletePitch />}</Typography>
                    ) : (
                      ""
                    )}
                  </Popover>
                </div>
              ) : (
                ""
              )}
              <Button
                onClick={handleClickOpen}
                variant="contained"
                size="large"
                sx={{
                  ...saveBtn,
                  width: "10rem",
                  margin: "5px",
                  fontSize: "60%",
                }}
              >
                Write pitch
              </Button>
            </div>
          }
          {/* Most important 250 work Accordian */}
          <div className="row my-4 d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6">
              <div className="Accordian text-light">
                <Accordion sx={AccordianBackground}>
                  <AccordionSummary
                    sx={{ color: "white" }}
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className="FingerprintAccordion"
                      sx={{ fontSize: "90%" }}
                    >
                      The Most Important 250 Words That You’ll Ever Speak or
                      Write
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "80%" }}
                      className="FingerprintAccordion_des"
                    >
                      Many creators and performers are so caught up in the idea
                      of being published, presented or produced that they never
                      stop to think about the “What’s Next?” Your success will
                      be measured, not by the completion of your creation, but
                      by the degree to which it will be shared, enjoyed and
                      rewarded (financially or otherwise).
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "80%" }}
                      className="FingerprintAccordion_des"
                    >
                      After you have completed a stellar project or work, you’ll
                      want to share it with the global marketplace, but often
                      you’ll need additional resources to bring it from personal
                      completion to wide-scale appreciation. This is where your
                      pitch comes into play.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "80%" }}
                      className="FingerprintAccordion_des"
                    >
                      Ideally, your pitch will convince audiences and key
                      players to back and support your work, and perhaps it will
                      convince investors to fund any resulting project with the
                      promise of large returns. On MeBookMeta, you will be able
                      to develop and perfect your pitch and present your best
                      self and work description to every person who visits your
                      profile.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "80%" }}
                      className="FingerprintAccordion_des"
                    >
                      Please take the time to write out an intriguing and
                      descriptive pitch for yourself, hone it down to 250 words,
                      practice your delivery (in the mirror and/or to friends),
                      record and re-record until you arrive at a most effective
                      presentation, and finally upload the video and text to
                      your profile page.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "80%" }}
                      className="FingerprintAccordion_des"
                    >
                      If you need further encouragement and suggestions, please
                      check out the additional links on this page. Good luck!
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*       
      <MainModal
      Component = {ModalView[changeView]}
      handleClose = {handleMainModalClose}
      open={openMainMOdal}
      // ref={webcamRefs}
      // props={{webcamRefs,opneWebcam , captureVideo , newButton , handleStopCaptureVideo , handleStartCaptureVideo , seeYourPitch , handleDownloads}}
      /> */}
      {
        WebCamModal[changeView]
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDel}
        onClose={handleCloseDel}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDel}>
          <Box sx={styleDelete}>
            <Typography
              id="transition-modal-description"
              sx={{ colo: "black" }}
            >
              Do you want to delete Pitch Video ?
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                MarginBottom: 0,
              }}
            >
              <Button
                onClick={handleCloseDel}
                style={{ textTransform: "initial", fontSize: "1rem" }}
              >
                Cancel
              </Button>

              <Button
                onClick={deletePitchVideo}
                style={{
                  textTransform: "initial",
                  fontSize: "1rem",
                  color: "red",
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

    </div>

  );

}

export default WritePitch

